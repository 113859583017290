// src/components/Introductions.js

import {React} from "react";
import { Box, Avatar, Stack, Typography } from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

export default function Introductions() {

    return (
        <Box
        sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
            px: { xs: 5, sm: 5, md: 10, lg: 20, xl: 20 },
            mb: 10,
            width: 1,
            mt: 10,
            },
        }}>

            <Stack
            direction="row"
            spacing={5}
            justifyContent="space-evenly"
            
            >

                <Stack direction="column" alignItems="center">
                    
                    <Avatar alt="Prashanth Pillay" src="/prashanth.jpg" sx={{ width: { xs: 100, sm: 150, md: 200, lg: 200, xl: 200 }, height: { xs: 100, sm: 150, md: 200, lg: 200, xl: 200 } }}/>
                    
                    <Typography 
                        style={{color:"#FF9900", fontWeight:"bold"}} 
                        fontSize={{ xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '2rem', xl: '2rem' }}
                        pt={2}
                        >
                        Ash
                    </Typography>

                    <Typography 
                        style={{color:"#F7FAE5", fontWeight:"bold"}}
                        fontSize={{ xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '2rem', xl: '2rem' }}
                        > 
                        Co-Founder
                    </Typography>

                    <Stack 
                    direction="row" 
                    alignItems="center"
                    spacing={2}
                    >
                        <Box
                        sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}} style={{"&:hover": {backgroundColor: "blue !important"}}}>
                            <a href="https://www.linkedin.com/in/prashanth-pillay-6823241b4/" target="_blank" rel="noreferrer">
                                <LinkedInIcon sx={{ 
                                    width: { xs: 20, sm: 30, md: 40, lg: 40, xl: 40 }, 
                                    height: { xs: 20, sm: 30, md: 40, lg: 40, xl: 40 }, 
                                    }}
                                    style={{ color: '#F7FAE5' }}
                                    />
                            </a>
                        </Box> 
                        <Box
                        sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <a href="mailto:prashanthpillay2@gmail.com">
                                <EmailIcon sx={{ 
                                    width: { xs: 20, sm: 30, md: 40, lg: 40, xl: 40 }, 
                                    height: { xs: 20, sm: 30, md: 40, lg: 40, xl: 40 }, 
                                    }}
                                    style={{ color: '#F7FAE5' }}
                                    />
                            </a>
                        </Box>
                    </Stack>

                </Stack>

                <Stack direction="column" alignItems="center">
                    
                    <Avatar alt="Sankalp Saini" src="/sankalp.jpg" sx={{ width: { xs: 100, sm: 150, md: 200, lg: 200, xl: 200 }, height: { xs: 100, sm: 150, md: 200, lg: 200, xl: 200 } }}/>
                
                    <Typography 
                        style={{color:"#FF9900", fontWeight:"bold"}} 
                        fontSize={{ xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '2rem', xl: '2rem' }}
                        pt={2}
                        >
                        Sankalp
                    </Typography>

                    <Typography 
                        style={{color:"#F7FAE5", fontWeight:"bold"}}
                        fontSize={{ xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '2rem', xl: '2rem' }}
                        > 
                        Co-Founder
                    </Typography>

                    <Stack 
                    direction="row" 
                    alignItems="center"
                    spacing={2}
                    >
                        <Box
                        sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}} style={{"&:hover": {backgroundColor: "blue !important"}}}>
                            <a href="https://www.linkedin.com/in/sankalp-saini/" target="_blank" rel="noreferrer">
                                <LinkedInIcon sx={{ 
                                    width: { xs: 20, sm: 30, md: 40, lg: 40, xl: 40 }, 
                                    height: { xs: 20, sm: 30, md: 40, lg: 40, xl: 40 }, 
                                    }}
                                    style={{ color: '#F7FAE5' }}
                                    />
                            </a>
                        </Box> 
                        <Box
                        sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <a href="mailto:sankalpsaini6@gmail.com">
                                <EmailIcon sx={{ 
                                    width: { xs: 20, sm: 30, md: 40, lg: 40, xl: 40 }, 
                                    height: { xs: 20, sm: 30, md: 40, lg: 40, xl: 40 }, 
                                    }}
                                    style={{ color: '#F7FAE5' }}
                                    />
                            </a>
                        </Box>
                    </Stack>

                </Stack>

                {/* <Stack direction="column" alignItems="center">
               
                    <Avatar alt="Nicholas Chubbs-Hart" src="/nick.jpg" sx={{ width: { xs: 100, sm: 150, md: 200, lg: 200, xl: 200 }, height: { xs: 100, sm: 150, md: 200, lg: 200, xl: 200 } }}/>

                    <Typography 
                        style={{color:"#FF9900", fontWeight:"bold"}} 
                        fontSize={{ xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '2rem', xl: '2rem' }}
                        pt={2}
                        >
                        Nicholas
                    </Typography>

                    <Typography 
                        style={{color:"#F7FAE5", fontWeight:"bold"}}
                        fontSize={{ xs: '1rem', sm: '1.5rem', md: '1.5rem', lg: '2rem', xl: '2rem' }}
                        > 
                        Co-Founder
                    </Typography>

                    <Stack 
                    direction="row" 
                    alignItems="center"
                    spacing={3}
                    >
                        <Box
                        sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}} style={{"&:hover": {backgroundColor: "blue !important"}}}>
                            <a href="https://www.linkedin.com/in/nicholas-chubbs-hart-59b604230/" target="_blank" rel="noreferrer">
                                <LinkedInIcon sx={{ 
                                    width: { xs: 20, sm: 30, md: 40, lg: 40, xl: 40 }, 
                                    height: { xs: 20, sm: 30, md: 40, lg: 40, xl: 40 }, 
                                    }}
                                    style={{ color: '#F7FAE5' }}
                                    />
                            </a>
                        </Box> 
                    </Stack>

                </Stack> */}

            </Stack>
        </Box>
    );
}