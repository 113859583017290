// src/components/About.js

import React, { useState } from 'react';
import { winningTickerArray, losingTickerArray, randomTickerArray }  from '../tools/DemoBaskets';
import getRandomSubset from '../tools/getRandomSubset.js';
import { Box, TextField, Stack, Button, Typography, Modal, Switch } from "@mui/material";
import ResponsiveAppBar from "../components/navbar-components/NavBar";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
// import Tooltip from '@mui/material';

const style = {
    position: 'absolute',
    justifyContent:'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius:10,
    boxShadow: 24,
    p: 4,
  };

export default function Demo() {

    const [isGraphVisible, setGraphVisibility] = useState(false);
    // const [textField, setTextField] = useState("");
    // const [wins_bar, setWinsBar] = useState("");
    // const [losses_bar, setLossesBar] = useState("");
    const[spy_data, set_spy_data] = useState("");
    const[algorithm_data, set_algorithm_data] = useState("");
    const[bought_tickers, set_bought_tickers] = useState("");
    const[algo_pl, set_algo_pl] = useState("");
    const [textFieldValue, setTextFieldValue] = useState("");
    const [switcherOnCustom, setSwitcherOnCustom] = useState(false);

    let results;

    const handleSwitcher = () => {
        setSwitcherOnCustom(!switcherOnCustom);
        setTextFieldValue("");
    }

    const handleWinningBasket = () => {
        setTextFieldValue(winningTickerArray.join('\n'));
        // setTextField(winningTickerArray.join('\n'));
    }

    const handleLosingBasket = () => {
        setTextFieldValue(losingTickerArray.join('\n'));
        // setTextField(losingTickerArray.join('\n'));
    }

    const handleRandomBasket = () => {
        const randomStocks = getRandomSubset(randomTickerArray, 5).join('\n');
        setTextFieldValue(randomStocks);
        // setTextField(randomStocks);
    }

    const preset1 = async () => {
        const tickers = textFieldValue;
        const response = await fetch('./api/algoPreset1', 
            {method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(tickers)
            }).then(response => response.json()).then(data => {
                results = data;
                console.log(results);
                // setWinsBar(results.avg_win*results.wins);
                // setLossesBar(results.avg_loss*results.losses);
                const temp_spy_data = results.dates.map((date,index) => ({
                    x: new Date(date), 
                    y: results.spy_cumulative_percentage[index]
                }));
                const temp_algo_data = results.dates.map((date, index) => ({
                    x: new Date(date),
                    y: results.accumulate_pl[index]
                }));
                console.log(temp_algo_data)
                set_spy_data(temp_spy_data);
                set_algorithm_data(temp_algo_data);
                set_bought_tickers(results.bought_tickers);
                set_algo_pl(results.algo_pl);
                setGraphVisibility(true);
            })
            return response;
    }
    const preset2 = async () => {
        const tickers = textFieldValue;
        const response = await fetch('./api/algoPreset2', 
            {method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(tickers)
            }).then(response => response.json()).then(data => {
                results = data;
                console.log(results);
                // setWinsBar(results.avg_win*results.wins);
                // setLossesBar(results.avg_loss*results.losses);
                const temp_spy_data = results.dates.map((date,index) => ({
                    x: new Date(date), 
                    y: results.spy_cumulative_percentage[index]
                }));
                const temp_algo_data = results.dates.map((date, index) => ({
                    x: new Date(date),
                    y: results.accumulate_pl[index]
                }));
                console.log(temp_algo_data)
                set_spy_data(temp_spy_data);
                set_algorithm_data(temp_algo_data);
                set_bought_tickers(results.bought_tickers);
                set_algo_pl(results.algo_pl);
                setGraphVisibility(true);
            })
            return response;
    }
    const preset3 = async () => {
        const tickers = textFieldValue;
        const response = await fetch('./api/algoPreset3', 
            {method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(tickers)
            }).then(response => response.json()).then(data => {
                results = data;
                console.log(results);
                // setWinsBar(results.avg_win*results.wins);
                // setLossesBar(results.avg_loss*results.losses);
                const temp_spy_data = results.dates.map((date,index) => ({
                    x: new Date(date), 
                    y: results.spy_cumulative_percentage[index]
                }));
                const temp_algo_data = results.dates.map((date, index) => ({
                    x: new Date(date),
                    y: results.accumulate_pl[index]
                }));
                console.log(temp_algo_data)
                set_spy_data(temp_spy_data);
                set_algorithm_data(temp_algo_data);
                set_bought_tickers(results.bought_tickers);
                set_algo_pl(results.algo_pl);
                setGraphVisibility(true);
            })
            return response;
    }

    const saveResults = async () => {
        try {
            const response = await fetch('./api/saveResults', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({algorithm_data, bought_tickers, algo_pl})
            });
    
            if (!response.ok) {
                throw new Error('Failed to download file');
            }
    
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'trade_results.xls');
    
            document.body.appendChild(link);
            link.click();
    
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
    // const change = event => {
    //     setTextField(event.target.value)
    // }

    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => setOpen4(false);
    return (

        <main className="bg-prophecy_navy" style={{ minHeight: '100vh'}}>
            <ResponsiveAppBar />
            
            <Box
            sx={{
                display: 'flex',
                flexDirection:"column",  
                alignItems: 'center',
                mt: 10,
                width:1,
            }}>
                <Stack
                direction="row"
                spacing = {{ xs: 5, s: 5, md: 2, lg: 5, xl: 10 }}
                sx={{
                    mx: { xs: 5, s: 5, md: 5, lg: 'auto', xl: 'auto' },
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >

                    {!switcherOnCustom && (
                    <Stack
                    direction="column"
                    justifyContent="space-evenly"
                    spacing={10}
                    >
                            <Button 
                            variant="outlined" 
                            sx={{width:100, height:70, border: '2px solid'}} 
                            style={{borderColor:"#276382", color:"#fff", fontWeight:"bold"}}
                            onClick = {handleWinningBasket}>
                                Winning Basket
                            </Button>

                            <Button 
                            variant="outlined" 
                            sx={{width:100, height:70, border: '2px solid'}} 
                            style={{borderColor:"#276382", color:"#fff", fontWeight:"bold"}}
                            onClick = {handleLosingBasket}>
                                Losing Basket
                            </Button>

                            <Button 
                            variant="outlined" 
                            sx={{width:100, height:70, border: '2px solid'}} 
                            style={{borderColor:"#276382", color:"#fff", fontWeight:"bold"}}
                            onClick = {handleRandomBasket}>
                                Random Basket
                            </Button>
                    </Stack>
                    )}

                    <Stack
                    direction="column"
                    justifyContent="space-evenly"
                    spacing={1}
                    >
                        {switcherOnCustom ? (
                            // Custom basket
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Stocks"
                                multiline
                                minRows={20}
                                maxRows={20}
                                value={textFieldValue}
                                onChange={(e) => setTextFieldValue(e.target.value)} // Allow editing
                                style={{backgroundColor:"#fff", borderRadius:30}}
                                InputProps={{
                                    style: {
                                        borderRadius: "30px",
                                    },
                                    readOnly: false,
                                    }}
                                sx={{
                                    height: 1,
                                }}
                            />
                        ) : (
                            // Preset basket
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Stocks"
                                multiline
                                minRows={20}
                                maxRows={20}
                                value={textFieldValue}
                                style={{backgroundColor:"#fff", borderRadius:30}}
                                InputProps={{
                                    style: {
                                        borderRadius: "30px",
                                    },
                                    readOnly: true,
                                    }}
                                sx={{
                                    height: 1,
                                }}
                            />
                        )}

                        <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }} justifyContent="center">
                            <Typography style={{color:"#fff", fontWeight:"bold"}}>Custom</Typography>
                            <Switch defaultChecked onClick = {handleSwitcher}></Switch>
                            <Typography style={{color:"#fff", fontWeight:"bold"}}>Basket</Typography>
                        </Stack>

                    </Stack>

                    <InfoOutlinedIcon 
                    style={{color: "#F7FAE5", marginLeft:5, alignSelf: 'flex-start', marginTop: 30}}
                    onClick = {handleOpen4}>
                    </InfoOutlinedIcon>
                    
                    <Modal
                    open={open4}
                    onClose={handleClose4}
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
                                Stock List
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                You can populate 5 stocks into the text box by clicking on one of the three buttons on the left.
                                <br></br>
                                <br></br>
                                To test a winning basket, click on the "Winning Basket" button.
                                <br></br>
                                <br></br>
                                To test a losing basket, click on the "Losing Basket" button.
                                <br></br>
                                <br></br>
                                To test a basket of 5 random stocks, click on the "Random Basket" button.
                            </Typography>
                        </Box>
                    </Modal>

                    <Stack
                    direction="column"
                    justifyContent="space-evenly"
                    spacing={10}
                    // sx={{ flex: 1 }}
                    >
                        <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        >
                            <Button 
                            variant="outlined" 
                            sx={{width:350, height:50, border: '3px solid'}} 
                            style={{borderColor:"#FF9900", color:"#fff", fontWeight:"bold"}}
                            onClick = {preset1}>
                                20 day Moving Average Algorithm
                            </Button>

                            <InfoOutlinedIcon 
                            style={{color: "#F7FAE5", cursor: "pointer"}}
                            onClick = {handleOpen1}>
                            </InfoOutlinedIcon>
                            
                            
                            <Modal
                            open={open1}
                            onClose={handleClose1}
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
                                        20 day Moving Average Algorithm
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Each value of a moving average is the average of the past n days of closing data, in this case, 20 days. This Algorithm
                                        checks when the 20 day moving average is increasing, meaning that the closing value is higher than it was 20 days ago,
                                        at which point it buys the stock. When the moving average starts decreasing, or alternatively when the closing value is less than it was
                                        20 days ago, the stock is sold. Each buy and sell constitutes one complete trading event and a win is an event where the stock is sold for
                                        more than it was bought while a loss is an event where the stock is sold for less than it was bought.
                                    </Typography>
                                </Box>
                            </Modal>
                        </Stack>
                        
                        <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        >
                            <Button variant="outlined" sx={{width:350, height:50, border: '3px solid'}} 
                            style={{borderColor:"#FF9900", color:"#fff" , fontWeight:"bold"}}
                            onClick = {preset2}>12 day Moving Average Algorithm</Button>
                            
                            <InfoOutlinedIcon 
                            style={{color: "#F7FAE5", cursor: "pointer"}}
                            onClick = {handleOpen2}>
                            </InfoOutlinedIcon>
                            
                            <Modal
                            open={open2}
                            onClose={handleClose2}
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
                                        12 day Moving Average Algorithm
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Each value of a moving average is the average of the past n days of closing data, in this case, 12 days. This Algorithm
                                        checks when the 12 day moving average is increasing, meaning that the closing value is higher than it was 12 days ago,
                                        at which point it buys the stock. When the moving average starts decreasing, or alternatively when the closing value is less than it was
                                        12 days ago, the stock is sold. Each buy and sell constitutes one complete trading event and a win is an event where the stock is sold for
                                        more than it was bought while a loss is an event where the stock is sold for less than it was bought.
                                    </Typography>
                                </Box>
                            </Modal>
                        </Stack>
                        
                        <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        >
                            <Button variant="outlined" sx={{width:350, height:50, border: '3px solid'}} 
                            style={{borderColor:"#FF9900", color:"#fff", fontWeight:"bold"}}
                            onClick = {preset3}>8 day Moving Average Algorithm</Button>

                            <InfoOutlinedIcon 
                            style={{color: "#F7FAE5", cursor: "pointer"}}
                            onClick = {handleOpen3}>
                            </InfoOutlinedIcon>
                            
                            <Modal
                            open={open3}
                            onClose={handleClose3}
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
                                        8 day Moving Average Algorithm
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        Each value of a moving average is the average of the past n days of closing data, in this case, 8 days. This Algorithm
                                        checks when the 8 day moving average is increasing, meaning that the closing value is higher than it was 8 days ago,
                                        at which point it buys the stock. When the moving average starts decreasing, or alternatively when the closing value is less than it was
                                        8 days ago, the stock is sold. Each buy and sell constitutes one complete trading event and a win is an event where the stock is sold for
                                        more than it was bought while a loss is an event where the stock is sold for less than it was bought.
                                    </Typography>
                                </Box>
                            </Modal>
                        </Stack>

                        <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        >
                            <Button variant="outlined" sx={{width:350, height:50, border: '3px solid'}} 
                            style={{borderColor:"#FF9900", color:"#fff", fontWeight:"bold"}}
                            onClick = {saveResults}>Save Trading Results To Excel</Button>

                        </Stack>
                    </Stack>

                    {isGraphVisible && (

                    <>
                        <div style={{ marginBottom: '20px' }}>
                            <LineChart
                                width={500}
                                height={600}
                                series={[
                                    {
                                        // Algorithm data series
                                        data: algorithm_data.map(point => point.y),
                                        label: "Cumulative Return of Algorithm",
                                        // lineStyle: { stroke: "#FF9900" }, // Optional: Change line color
                                        labelStyle: {fill:"white"},
                                        color: "#FF9900", 
                                        showMark: false,
                                    },
                                    {
                                        // S&P 500 data series
                                        data: spy_data.map(point => point.y),
                                        label: "Cumulative Return of S&P 500",
                                        // lineStyle: { stroke: "#276382" }, // Optional: Change line color
                                        labelStyle: {fill:"white"},
                                        color: "#276382",
                                        showMark: false,
                                    },
                                ]}
                                slotProps={{
                                    legend: {
                                        labelStyle: {
                                        fontSize: 16,
                                        fill: 'white',
                                        },
                                    },
                                    }}
                                xAxis={[
                                    {
                                        scaleType: "time", // Use a time-based x-axis
                                        data: algorithm_data.map(point => point.x), // Use the x-axis from algorithm_data
                                        tickLabelStyle: { fill: "white" }, // Make x-axis tick labels white
                                        color: "white",
                                    },
                                ]}
                                yAxis={[
                                    {
                                        tickLabelStyle: { fill: "white" }, // Make y-axis tick labels white
                                        color: "white",
                                    },
                                ]}
                                title="Performance Comparison" // Simple string for the title
                                sx={{
                                            //change left yAxis label styles
                                            "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel":{
                                            strokeWidth:"0.4",
                                            fill:"white"
                                            },
                                            // change all labels fontFamily shown on both xAxis and yAxis
                                            "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel":{
                                                fontFamily: "Roboto",
                                            },
                                            "& .MuiMarkElement": {
                                                fill: "white",
                                            },
                                            // change bottom label styles
                                            "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel":{
                                                strokeWidth:"0.5",
                                                fill:"white"
                                                },
                                                // bottomAxis Line Styles
                                                "& .MuiChartsAxis-bottom .MuiChartsAxis-line":{
                                                stroke:"white",
                                                strokeWidth:0.4
                                                },
                                                // leftAxis Line Styles
                                                "& .MuiChartsAxis-left .MuiChartsAxis-line":{
                                                stroke:"white",
                                                strokeWidth:0.4
                                                },
                                                "& .MuiChartsLegend-root": {
                                                color: "white",
                                                strokeWidth:0.4
                                                },
                                            }}
                            />
                        </div>
                    </>
                    // <BarChart
                    //     xAxis={[{ scaleType: 'band', data: ['Trade Results'] }]}
                    //     series={[
                    //         { 
                    //           data: [wins_bar.toFixed(2)], 
                    //           label: "Total Win Percentage", 
                    //           color: "#77DD77", 
                    //         },
                    //         { 
                    //           data: [losses_bar.toFixed(2)], 
                    //           label: "Total Loss Percentage", 
                    //           color: "#FF6961", 
                    //         }
                    //       ]}
                    //     width={500}
                    //     height={600}    
                    //     slotProps={{
                    //         legend: {
                    //           labelStyle: {
                    //             fontSize: 16,
                    //             fill: 'white',
                    //           },
                    //         },
                    //       }}
                    //     sx={{
                    //         //change left yAxis label styles
                    //         "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel":{
                    //         strokeWidth:"0.4",
                    //         fill:"white"
                    //         },
                    //         // change all labels fontFamily shown on both xAxis and yAxis
                    //         "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel":{
                    //             fontFamily: "Roboto",
                    //         },
                    //         // change bottom label styles
                    //         "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel":{
                    //             strokeWidth:"0.5",
                    //             fill:"white"
                    //             },
                    //             // bottomAxis Line Styles
                    //             "& .MuiChartsAxis-bottom .MuiChartsAxis-line":{
                    //             stroke:"white",
                    //             strokeWidth:0.4
                    //             },
                    //             // leftAxis Line Styles
                    //             "& .MuiChartsAxis-left .MuiChartsAxis-line":{
                    //             stroke:"white",
                    //             strokeWidth:0.4
                    //             },
                    //             "& .MuiChartsLegend-root": {
                    //             color: "white",
                    //             strokeWidth:0.4
                    //             },
                    //         }}
                    //     />
                    )}
                </Stack>
            </Box>
        </main>
    );
}