export const losingTickerArray = [
    'PTON',
    'SBH',
    'LAZR',
    'SFIX',
    'OLPX',
];

export const winningTickerArray = [
    'IBM',
    'AAPL',
    'MSFT',
    'AMZN',
    'GOOGL',
];

export const randomTickerArray = [
    'PTON',
    'SBH',
    'LAZR',
    'SFIX',
    'OLPX',
    'CCL',
    'LEVI',
    'WWW',
    'NCLH',
    'ACVA',
    'LESL',
    'UAA',
    'HBI',
    'DBI',
    'EVGO',
    'MCW',
    'FIGS',
    'CUK',
    'SG',
    'MAT',
    'GIII',
    'CHPT',
    'AEO',
    'PLYA',
    'CARG',
    'ROIV',
    'UA',
    'CVNA',
    'QS',
    'LCID',
    'GT',
    'SABR',
    'WOOF',
];